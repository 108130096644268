@import url('https://fonts.googleapis.com/css2?family=Bagel+Fat+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
.home__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 52px;
    font-family: "Roboto", sans-serif;
}

.home__container .home__container__navbar {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 22px;
    padding-right: 22px;
    -webkit-box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19); 
    box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19);
    height: 92px;
    border-radius: 12px;
    margin-bottom: 38px;
    background: radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%);
}

.home__container .home__container__navbar h1 {
    font-family: 'Source Code Pro', monospace;
    color: #fff;
}

.home__container .home__container__second {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
    -webkit-box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19); 
    box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19);
    height: 92px;
    border-radius: 12px;
}

.home__container .home__container__second h2 {
    color: #0023A3;
    font-size: 34px;
    font-weight: 900;
}

.home__container .home__container__navbar img {
    height: 100%;
}

.home__container .home__container__first {
    width: 80%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    -webkit-box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19); 
    box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19);
    border-radius: 12px;
    margin-bottom: 38px;
}

.home__container .shapes {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home__container .measure {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home__container .measure .rectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    border: 2px dashed black;
    border-radius: 0px;
    margin-bottom: 38px;
    margin-right: 21px;
}

.home__container .circle {
    border-radius: 50%;
    border: 2px solid black;
    position: relative;
    margin-right: 120px;
}

.home__container .circle .line {
    position: absolute;
    width: 120%;
    height: 2px;
    background: black;
    top: 50%;
    left: -10%;
}

.home__container .home__container__first .vertical__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    width: 90%;
}

.home__container .home__container__first .vertical__container .input__container {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.home__container .home__container__first .vertical__container .input__container.top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 48%;
}

.home__container .home__container__first .vertical__container .input__container.top p {
    border: 1px solid black;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
}

.home__container .home__container__first .vertical__container .input__container__special {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.home__container .home__container__first .vertical__container .input__container p {
    font-size: 18px;
    font-weight: 500;
}

.home__container .home__container__first .vertical__container .input__container__special p {
    font-size: 18px;
    font-weight: 500;
}

.home__container .home__container__first .vertical__container .input__container input {
    height: 32px;
    font-size: 18px;
    padding: 7px;
}

.home__container .home__container__first .vertical__container .input__container__special input {
    height: 32px;
    font-size: 18px;
    padding: 7px;
}

.home__container .home__container__first .vertical__container .input__container select {
    height: 30px;
    font-size: 18px;
}

.home__container .home__container__results__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    flex-wrap: wrap;
}

.home__container .home__container__results__container .home__container__results {
    width: 48%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px;
    -webkit-box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19); 
    box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19);
    border-radius: 12px;
    margin-bottom: 38px;
}

.home__container .home__container__results__container .home__container__results.airflow {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px;
    -webkit-box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19); 
    box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19);
    border-radius: 12px;
    margin-bottom: 38px;
}

.home__container .home__container__results__container .home__container__results.size {
    width: 30%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px;
    -webkit-box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19); 
    box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19);
    border-radius: 12px;
    margin-bottom: 38px;
}

.home__container .home__container__results__container .math {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30%;
    font-size: 28px;
    color:#32abf1;
}
.home__container .home__container__results__container .math h1 {
    cursor: pointer;
}
.home__container .home__container__results__container .home__container__size {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    -webkit-box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19); 
    box-shadow: 3px 5px 20px 7px rgba(0,0,0,0.19);
    border-radius: 12px;
    margin-bottom: 38px;
}


.home__container .home__container__first button {
    width: 90%;
    height: 34px;
    margin-top: 22px;
    background-color: #32abf1;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.home__container .home__container__first button:hover {
    background-color: #2784bb;
}

.home__container .home__container__results__container .home__container__size select {
    height: 32px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    width: 80%;
    margin-top: 12px;
}

.svgContainer {
    background-color: black;
    width: 100%;
    height: 100vh;
}

svg {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 150px;
	width: 150px;
}

.home__container .visuals {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-bottom: 22px;
    height: 220px;
    align-items: center;
    justify-content: space-between;
}

.home__container .visuals .vis__left {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid black;
}

.home__container .visuals .vis__left::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: black;
    transform: translateY(-50%);
}

.home__container .visuals .vis__left .number {
    position: absolute;
    top: 30%; /* Adjust this as needed to position the number */
    left: 0;
    right: 0;
    text-align: center;
    font-size: 2em;
}

.home__container .visuals .vis__right_cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}




.home__container .visuals .vis__right_cont .vis__right {
    position: relative;
    border: 1px solid black;
    margin: 50px; /* provide enough space for arrows */;
}

.home__container .visuals .vis__right_cont .vis__right .ltwo {
    position: relative;
    top: -30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home__container .visuals .vis__right_cont .vis__right .lone {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;
    left: 65%;
}

.home__container .visuals .vis__right_cont .vis__right::before, .vis__right::after {
    content: "";
    position: absolute;
    width: 50px; /* length of the arrow */
    height: 1px; /* thickness of the arrow */
    background: black; /* color of the arrow */
}

.home__container .visuals .vis__right_cont .vis__right::before {
    top: -10%; 
    left: 50%; 
    transform: translateX(-50%);
    height: 2px;
    width: 100%;
}

.home__container .visuals .vis__right_cont .vis__right::after {
    right: 0; 
    top: 50%; 
    left: 105%;
    transform: translateY(-50%);
    height: 100%; /* length of the arrow */
    width: 2px; /* thickness of the arrow */
}

.home__container .duct__button {
    height: 42px;
    margin-bottom: 22px;
    width: 80%;
    background: radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%);
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

@media screen and (max-width: 930px) {
    .home__container .visuals {
        display: flex;
        flex-direction: row;
        width: 80%;
        margin-bottom: 22px;
        height: 220px;
        align-items: center;
        justify-content: space-between;
    }
}




















.projects-catalog .catalog-slider {
  margin: 50px 0px;
}

.projects-catalog .catalog-cover {
  position: relative;
}

.projects-catalog ul {
  white-space: nowrap;
  overflow-x: auto;
}

.projects-catalog li {
  width: 75%;
  height: 200px;
}

li.catalog-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.projects-catalog li {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 350px;
  height: 250px;
  background: #222;
}


@media screen and (max-width: 800px) {
    .home__container .shapes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .home__container .home__container__first .vertical__container .input__container.top {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home__container .home__container__first .vertical__container .input__container.top p {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .home__container .home__container__results__container {
        width: 100%;
    }

    .home__container .duct__button {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .home__container .visuals {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 22px;
        height: 220px;
        align-items: center;
        justify-content: space-between;
    }
}

@media screen and (max-width: 620px) {
    .home__container .home__container__results__container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .home__container .home__container__results__container .home__container__results {
        width: 100%;
    }

    .home__container .home__container__results.size {
        width: 100% !important;
    }
}


@media screen and (max-width: 590px) {

    .home__container .home__container__first {
        width: 100%;
    }

    .home__container .home__container__first .vertical__container {
        width: 100%;
    }

    .home__container .home__container__results__container {
        width: 100%;
        text-align: center;
    }

    .home__container .home__container__results__container .home__container__results {
        width: 100%;
    }

    .home__container .home__container__second {
        height: 100%;
        text-align: center;
    }

    .home__container .home__container__second h2 {
        font-size: 24px;
    }


    .home__container .visuals {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 22px;
        height: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .home__container .home__container__navbar h1 {
        font-size: 24px;
    }

    .home__container .home__container__navbar img {
        height: 80%;
    }
}

@media screen and (max-width: 480px) {
    .home__container {
        padding: 10px;

    }
    .home__container .home__container__navbar img {
        height: 40%;
    }
}